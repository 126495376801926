import { useTranslation } from 'react-i18next';

// material-ui
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';

// project import
import { PopupTransition } from 'components/@extended/Transitions';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { acceptTermsApi } from 'services/user';

// assets

// types
interface Props {
  open: boolean;
  handleClose: () => void;
}

// ==============================|| CUSTOM DELETE ||============================== //

const AcceptTermsDialog = ({ open, handleClose }: Props) => {
  const { user } = useAuth();
  const { i18n } = useConfig();
  const { t } = useTranslation('common');

  const handleAcceptTerms = async () => {
    const isSuccess = await acceptTermsApi(user?.id || '0', i18n);
    if (isSuccess) handleClose();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="md"
      fullWidth
      aria-labelledby="accept-terms-title"
      aria-describedby="accept-terms-description"
    >
      <DialogTitle
        sx={{
          fontSize: '16px',
          fontWeight: 700
        }}
      >
        {t('accept-terms.title')}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography
          align="left"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            whiteSpace: 'pre-line'
          }}
        >
          {t('accept-terms.content')}
        </Typography>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button color="primary" variant="contained" onClick={handleAcceptTerms} id="accept-terms-modal-confirm-button" autoFocus>
            {t('buttons.understand')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptTermsDialog;
